.Loader {
  &.is-white {
    .lds-ring div {
      border-color: #fff transparent transparent transparent;
    }
  }

  &.is-small {
    .lds-ring {
      width: 28px;
      height: 28px;
    }
    .lds-ring div {
      width: 28px;
      height: 28px;
      margin: 0;

      &:nth-child(3),
      &:nth-child(4) {
        display: none;
      }
    }
  }

  .description {
    text-align: center;
  }

  .lds-ring {
    display: block;
    margin: 0 auto;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #ddd;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #ddd transparent transparent transparent;
  }

  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }

  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }

  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
