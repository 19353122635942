.new-bubble .new-bubble-outer-dot {
  position: relative;
  margin: 1px;
  display: block;
  text-align: center;
  opacity: 1;
  background-color: rgba(0, 149, 255, 0.4);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  animation: new-bubble-pulse 1.5s linear infinite;
}

.new-bubble {
  display: block;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.new-bubble::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 17px;
  background-color: #3af;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
}

.new-bubble .new-bubble-inner-dot {
  display: block;
  text-align: center;
  opacity: 1;
  background-color: rgba(0, 149, 255, 0.4);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  -webkit-animation: new-bubble-pulse 1.5s linear infinite;
  -moz-animation: new-bubble-pulse 1.5s linear infinite;
  -o-animation: new-bubble-pulse 1.5s linear infinite;
  animation: new-bubble-pulse 1.5s linear infinite;
}

.new-bubble .new-bubble-inner-dot:after {
  content: "";
  display: block;
  text-align: center;
  opacity: 1;
  background-color: rgba(0, 149, 255, 0.4);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  -webkit-animation: new-bubble-pulse 1.5s linear infinite;
  -moz-animation: new-bubble-pulse 1.5s linear infinite;
  -o-animation: new-bubble-pulse 1.5s linear infinite;
  animation: new-bubble-pulse 1.5s linear infinite;
}

@keyframes new-bubble-pulse {
  0% {
    transform: scale(1);
    opacity: 0.75;
  }
  25% {
    transform: scale(1.35);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.7);
    opacity: 0;
  }
}
