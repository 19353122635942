.App.default .AudioPlayer {
  position: relative;

  &.is-playing .overlay .background {
    background: rgba(0, 0, 0, 0.4);
  }

  .artwork {
    display: block;
    position: relative;
    min-height: 225px;
    height: var(--var-album-artwork-height);
    background-color: var(--var-accent-color);
    border-radius: 5px;
    width: 100%;
    overflow: hidden;

    .album-art {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &:hover {
      .background {
        background: rgba(0, 0, 0, 0.7);
      }
    }

    .track-meta {
      .track-title,
      p.track-progress {
        display: block;
        font-size: 1.25rem;
        color: white !important;
        text-align: center;
        background: transparent;
        border: none;
        box-shadow: none;
        margin: 1rem auto;
      }

      .track-title {
        font-weight: bold;
        max-width: 90%;
        width: 100%;
      }
    }

    .controls {
      display: flex;
      position: relative;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 15px;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      z-index: 100;

      & > .fluid-container,
      & > .fluid-container > .columns {
        width: 100%;
        margin: auto;
      }

      button {
        background: transparent;
        border: none;

        &:focus {
          outline: none;
        }
      }

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        i {
          position: relative;
          font-size: 2.25rem;
          color: white;
          transition: color 0.2s ease-in-out;
          z-index: 110;

          &:hover {
            cursor: pointer;
            color: #fff;
          }
        }
      }
    }

    .background {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.1);
      transition: background 0.2s ease-in-out;
    }
  }
}

.App.small .AudioPlayer {
  position: relative;
  padding: 10px;
  background-color: var(--var-accent-color);

  &.is-playing .overlay .background {
    background: transparent;
  }

  .track-meta {
    .track-title,
    p.track-progress {
      display: block;
      font-size: 1rem;
      color: var(--var-accent-text-color) !important;
      text-align: center;
      background: transparent;
      border: none;
      box-shadow: none;
      margin: 0 auto;
    }

    .track-progress {
      margin-bottom: 0.5rem !important;
    }

    .track-title {
      width: 100%;
    }
  }

  .artwork {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 75px;

    & > * {
      display: flex;
    }
  }

  .artwork img {
    width: 75px;
    height: 75px;
    border-radius: 100%;
  }

  .overlay {
    flex: 1;
    justify-content: center;
    align-items: center;

    .controls {
      width: 100%;
      box-sizing: border-box;
      padding: 0 0.5rem;
      z-index: 100;

      .columns {
        margin: 0 auto;
      }

      button {
        background: transparent;
        border: none;

        &:focus {
          outline: none;
        }
      }

      .icon {
        width: 100%;

        i {
          position: relative;
          font-size: 2rem;
          color: var(--var-accent-text-color);
          transition: color 0.2s ease-in-out;
          z-index: 110;

          &:hover {
            cursor: pointer;
            color: #fff;
          }
        }
      }
    }

    .background {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent;
      transition: background 0.2s ease-in-out;
    }
  }
}
