html,
body,
.App {
  background-color: transparent !important;
  overflow-y: hidden !important;
}

.App {
  background-color: var(--var-background-color) !important;
  padding: 15px 10px;

  &.has-box-shadow-hidden {
    .card {
      box-shadow: none;
    }
  }

  &.is-full-width {
    margin: 0;
    padding: 0;

    .container {
      max-width: 100%;
      margin: 0 auto;
    }

    .PublicPlayer {
      & > .container > .columns > .column {
        width: 100%;
        margin: 0;
        padding: 0;
      }

      .album {
        max-width: 100%;
      }
    }
  }

  *:not(.title):not(.subtitle):not(.button):not(i) {
    font-family: var(--var-body-font-family) !important;
    font-size: var(--var-body-font-size);
  }

  .PublicPlayer {
    & > .container > .columns {
      margin: 0 auto;
    }
  }

  .album {
    .card-content {
      padding: 0;
    }
    .card-footer {
      border-top: none;
    }
  }

  .faq-item * {
    color: var(--var-text-color) !important;
  }

  input {
    color: var(--var-accent-color) !important;
  }

  .title {
    font-family: var(--var-title-font-family);
    font-size: var(--var-title-font-size);
  }

  .subtitle {
    font-family: var(--var-title-font-family);
  }
}

.columns {
  max-width: 100%;
}

*.is-link {
  background-color: var(--var-accent-color) !important;
}

.has-accent-light {
  color: var(--var-accent-text-color) !important;
  background-color: var(--var-accent-light-color) !important;
}

button.is-link-like {
  background: transparent;
  border: none;
}

input[type="text"] {
  border: none;
  border-bottom: 5px solid #d9d9d9;
  max-height: 100px;
  text-align: center;
  font-size: 3em !important;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0;
  box-shadow: none;
}

.columns {
  display: flex !important;
}

@media screen and (max-width: 720px) {
  .input-container.container {
    .columns:first-child {
      margin-left: 0;
      margin-right: 0;
      .column {
        box-sizing: border-box;
        padding: 2px;
      }
    }
  }
}

@media screen and (max-width: 540px) {
  input[type="text"] {
    max-height: 70px;
  }
}

footer {
  flex-direction: column;

  .button {
    border-radius: 0;
    flex: 1;
    transition: opacity 0.2s ease-in-out;
    opacity: 1;

    &:hover {
      opacity: 0.9;
    }
  }
}

.album {
  max-width: 400px;
  display: block;
  margin: 0 auto;
}

.card {
  border-radius: 10px;
}

.track-list {
  max-height: var(--var-track-list-height);
  overflow-y: auto;
}

.music-player {
  display: none;
}

.menu-list button {
  display: block;
  width: 100%;
  border-radius: 2px;
  color: #4a4a4a;
  padding: 0.5em 0.75em;
  background-color: transparent;
  border: none;
  text-align: left;
  font-size: 16px;

  &:hover {
    background-color: whitesmoke;
    color: #363636;
  }

  &.is-active {
    color: var(--var-accent-text-color);
    background-color: var(--var-accent-color);
  }
}

.fa-circle-notch {
  animation: rotate;
  animation-duration: 1000ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
}
