.TrackItem {
  position: relative;
  overflow: hidden;

  button {
    &.is-active {
      color: var(--var-accent-text-color) !important;
    }

    & > div {
      width: 100%;
      display: flex;
      flex-direction: row;

      .track-index {
        width: 2rem;
        min-width: 2rem;
      }

      .track-info {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-self: stretch;

        .meta {
          display: block;
          font-size: 0.75rem !important;

          & > span {
            display: inline-block;
          }

          & > span:first-child {
            font-style: italic;
          }
        }
      }

      .new-bubble {
        width: 1rem;
        justify-self: center;
        align-self: center;
        padding: 0 1rem;
      }
    }
  }
}
