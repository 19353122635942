$dimensions: 16, 24, 32, 48, 64, 96, 128, 256, 512;
$link: #523b6d;
$redeem-color: #524661;

@import "~bulma/bulma.sass";
@import "./default-theme.scss";
@import "./small-theme.scss";

html,
body,
#root {
  min-height: 100vh;
}

.App.fill-parent {
  position: relative;
  min-height: 100vh;

  & > div {
    & > .container {
      min-height: 100vh;

      max-width: 100%;

      & > .columns > .column {
        width: 100%;
        margin: 0 auto;

        .album {
          position: relative;
          max-width: 100%;

          & > .card {
            height: 100%;
          }
        }
      }
    }
  }
}
