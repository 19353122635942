.App.small {
  .title {
    font-size: 1.25rem;
    font-size: var(--var-title-font-size);
  }
  .subtitle {
    font-size: 1.2rem;
    font-size: var(--var-body-font-size);
    margin-bottom: 0.5rem;
  }
}
